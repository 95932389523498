import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FilesCard } from './FilesCard';
import EditableOfferCard from '../EditableOfferCard/EditableOfferCard';
import { OfferCard } from './OfferCard';
import {
    DisclosedOrder,
    inEmittedState,
    inProcessingState,
    inValidatingState,
    isDisclosedOrder,
    PublicOrder,
} from '../../../../../Models';
import { PatientCard } from './PatientCard';
import { usePrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';
import { ChatBox } from '../ChatBox/ChatBox';

type OrderDialogContentProps = {
    order: PublicOrder | DisclosedOrder;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onDone: () => void;
    openChat: boolean;
};
export default function OrderDialogContent({
    order,
    setLoading,
    onDone,
    openChat,
}: OrderDialogContentProps) {
    const prescriptionData = usePrescriptionData(order);
    const {
        isLoading: isPrescriptionsLoading,
        hasOnlyElectronicPrescriptionData,
        prescriptionFilesUrls,
    } = prescriptionData;
    /**
     * - únicamente se OCULTAN los documentos en emitted:
     * -- si y sólo si TODAS las recetas son electrónicas CON prescription_data
     */
    const hideFiles = inEmittedState(order) && hasOnlyElectronicPrescriptionData;
    return (
        <DialogContent>
            <Grid2 container sx={{ mt: 1, height: 1 }} spacing={1}>
                <Grid2 xs={7} spacing={1} height="100%">
                    <Stack spacing={1} height="100%">
                        {isDisclosedOrder(order) && <PatientCard order={order} />}
                        {(inProcessingState(order) || order.state === 'fulfilled') && (
                            <OfferCard order={order} />
                        )}
                        {(inEmittedState(order) || inValidatingState(order)) &&
                            isPrescriptionsLoading && (
                                <Skeleton variant="rectangular" width="100%" height="100%" />
                            )}
                        {(inEmittedState(order) || inValidatingState(order)) &&
                            !isPrescriptionsLoading && (
                                <EditableOfferCard
                                    order={order}
                                    setLoading={setLoading}
                                    onDone={onDone}
                                    prescriptionData={prescriptionData}
                                />
                            )}
                    </Stack>
                </Grid2>
                {openChat && !inEmittedState(order) && (
                    <Grid2 xs height="100%">
                        <ChatBox />
                    </Grid2>
                )}
                {!openChat && !isPrescriptionsLoading && !hideFiles && (
                    <Grid2 xs height="100%">
                        <FilesCard individualFiles={prescriptionFilesUrls} order={order} />
                    </Grid2>
                )}
            </Grid2>
        </DialogContent>
    );
}
