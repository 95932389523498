import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Print from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import printJS, { Configuration } from 'print-js';
import { isIE } from 'react-device-detect';
import { OrderSection } from '../OrderSection';
import FileViewer from '../../../../../Components/FileViewer';
import {
    DisclosedOrder,
    isDisclosedOrder,
    isOTC,
    isPast,
    PublicOrder,
} from '../../../../../Models';
import { healthProviderTitle } from '../../../../../utils/utils';
import { getOrderDocumentsPdf } from '../../../../../services/api/files.endpoints';

const EMPTY_FILES_TEXT = `El paciente no envió recetas ni fotos en este pedido.\n
En caso de necesitar receta, apretá el botón `;

const getPrintableFiles = async (orderId: string) => {
    const { blobData } = await getOrderDocumentsPdf(orderId);
    let printableFilesUrl = '';
    if (blobData) {
        printableFilesUrl = URL.createObjectURL(blobData);
    }
    return printableFilesUrl;
};
export function FilesCard({
    individualFiles,
    order,
}: {
    individualFiles: string[];
    order: DisclosedOrder | PublicOrder;
}) {
    const [loadingPrint, setLoadingPrint] = useState(false);

    const onPrint = async () => {
        const files = await getPrintableFiles(order.id);
        setLoadingPrint(true);
        try {
            if (isIE) {
                // internet Explorer no soporta printJs
                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.src = files;
                iframe.onload = () => {
                    setTimeout(() => {
                        iframe.focus();
                        iframe.contentWindow?.print();
                    }, 1);
                };
            } else {
                const pdfsPrintConfig: Configuration = {
                    printable: files,
                    type: 'pdf',
                };
                // NOTA: el filename printJS lo toma desde header automaticamente
                printJS(pdfsPrintConfig);
            }
        } catch (e) {
            // última opción
            window.open(files, '_blank');
        }
        setLoadingPrint(false);
    };
    const onSave = async () => {
        const files = await getPrintableFiles(order.id);
        saveAs(files, `${order.short_id} ${healthProviderTitle(order)}.pdf`);
    };
    const filesCount = individualFiles.length;
    return (
        <OrderSection id="file-viewer" sx={{ height: '100%' }}>
            <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ pb: 0 }}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                    <ReceiptLongIcon color="action" />
                    <Typography variant="h6" fontWeight="medium">
                        {`Documentos del pedido (${filesCount || ''})`}
                    </Typography>
                </Stack>
                {(isDisclosedOrder(order) || isPast(order)) && !isOTC(order) && (
                    <Stack direction="row" justifyContent="end" alignItems="center">
                        <Tooltip title="Imprimir documentos de este pedido">
                            <LoadingButton
                                id="print-docs-button"
                                onClick={onPrint}
                                size="small"
                                loading={loadingPrint}
                                disabled={loadingPrint}
                                style={{ justifyContent: 'center' }}>
                                <Print />
                            </LoadingButton>
                        </Tooltip>
                        <Tooltip title="Guardar documentos de este pedido">
                            <LoadingButton
                                id="save-docs-button"
                                onClick={onSave}
                                size="small"
                                style={{ justifyContent: 'center' }}>
                                <DownloadIcon />
                            </LoadingButton>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>
            <Divider flexItem />
            {!individualFiles.length && (
                <Typography
                    variant="body1"
                    fontWeight="normal"
                    color="grey.400"
                    align="center"
                    marginTop={30}
                    marginX={10}
                    style={{ whiteSpace: 'pre-line' }}>
                    {EMPTY_FILES_TEXT}
                    <ErrorOutlineIcon sx={{ verticalAlign: 'middle' }} color="disabled" />
                </Typography>
            )}
            {!!individualFiles.length && <FileViewer files={individualFiles} />}
        </OrderSection>
    );
}
