import React, { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { FormContainer, useForm } from 'react-hook-form-mui';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid2 from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
    inEmittedState,
    inValidatingState,
    isOTC,
    isScheduled,
    PublicOrder,
    requiresApproval,
} from '../../../../../Models';
import { ProductsForm } from '../../../types/editableOfferCard.formValues';
import { OrderSection } from '../OrderSection';
import { createOffer, validateOffer } from '../../../../../services/api/orders.endpoints';
import { CreateOfferRequestDto } from '../../../../../services/api/dto/orders.dto';
import { getPrescriptionsCharge } from '../../../utils/getPrescriptionsCharge';
import { sanitizeProductsForm } from '../../../utils/sanitizeProductsForm';
import { getInitFormValues } from '../../../utils/getInitFormValues';
import { PrescriptionInput } from './ProductInputs';
import { ValidatingSubmitButton } from './ValidatingSubmitButton';
import { EmittedSubmitButton } from './EmittedSubmitButton';
import { PrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';
import { ScheduleDatePicker } from './ScheduleDatePicker';
import { ToPreviousStateButton } from './ToPreviousStateButton';
import { ReScheduleStock } from './ReScheduleStock';
import { SimpleProductItem } from './SimpleProductItem';

const filesInfoMessage = (isOtc: boolean) => {
    let firstMsg = 'Estas son las recetas del paciente.';
    if (isOtc) {
        firstMsg = 'Estos son los productos de venta libre del paciente.';
    }
    return `${firstMsg}\n 
Asegurarte de tener stock de todos los medicamentos.

Podés arrastrar la imagen con el mouse para moverla.

Podés programar stock para pedir en droguería o confirmar stock para entrega inmediata`;
};

function TotalAmount({ totalCharged }: { totalCharged: number }) {
    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="center">
            <Stack direction="row" spacing={2}>
                <Typography variant="h6" fontWeight="medium">
                    Total a cobrar:
                </Typography>
                <Typography id="total-charged" variant="h6" fontWeight="medium" color="primary">
                    <FormattedNumber
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        currencyDisplay="narrowSymbol"
                        value={totalCharged}
                        minimumFractionDigits={2}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
}

type DialogContent = {
    title: string;
    message: string;
};
const dialogs = {
    freeWarning: {
        title: '🧐 ¿Es gratis?',
        message: `Uno de los productos tiene precio $0.\n¿El producto es gratis?`,
    },
    lowPriceWarning: {
        title: '🧐 ¿Precio muy bajo?',
        message: `Uno de los productos tiene el precio bajo.\n¿El precio está bien ingresado?`,
    },
};
type EditableOfferCardProps = {
    order: PublicOrder;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onDone: () => void;
    prescriptionData: PrescriptionData;
};

function EditableOfferCard({
    order,
    setLoading,
    onDone,
    prescriptionData,
}: EditableOfferCardProps) {
    const {
        hasOnlyElectronicPrescriptionData,
        electronicProducts,
        otcProducts,
        prescriptionFilesUrls,
        isFetching: isPrescriptionDataFetching,
        isLoading: isPrescriptionDataLoading,
    } = prescriptionData;
    const [{ dialogOpen, onConfirmDialog, dialogContent }, setDialog] = useState<{
        dialogOpen: boolean;
        onConfirmDialog: () => Promise<void>;
        dialogContent: DialogContent;
    }>({
        dialogOpen: false,
        onConfirmDialog: () => Promise.resolve(),
        dialogContent: dialogs.freeWarning,
    });
    const formContext = useForm<ProductsForm>();
    const { watch, control } = formContext;

    const [isLoading, setIsLoading] = useState(true);
    const [scheduledEnabled, setScheduleEnabled] = useState(
        !!order.user_offer?.scheduled_stock_datetime,
    );

    useEffect(() => {
        if (isPrescriptionDataLoading || isPrescriptionDataFetching) {
            return;
        }
        if (!watch().products?.length) {
            getInitFormValues(order.user_offer, electronicProducts, otcProducts)
                .then((initialForm) => {
                    formContext.setValue('products', initialForm.products);
                    formContext.setValue(
                        'scheduled_stock_datetime',
                        initialForm.scheduled_stock_datetime,
                    );
                })
                .finally(() => setIsLoading(false));
        }
    }, [
        electronicProducts,
        formContext,
        watch,
        isPrescriptionDataLoading,
        order.user_offer,
        otcProducts,
        isPrescriptionDataFetching,
    ]);
    const totalPrescriptionCharge = getPrescriptionsCharge(watch().products);
    const inputState = requiresApproval(order) ? 'submit' : 'disabled';
    const handleSubmit = async () => {
        setLoading(true);
        if (inEmittedState(order)) {
            const createData: CreateOfferRequestDto = {};
            if (scheduledEnabled) {
                createData.scheduled_stock_datetime = watch().scheduled_stock_datetime;
            }
            try {
                await createOffer(order.id, createData);
            } catch (err) {
                console.error('EditableOfferCard => handleSubmit', err);
            } finally {
                onDone();
            }
        } else {
            const sanitizedProducts = sanitizeProductsForm(watch().products);
            const localValidateOffer = () =>
                validateOffer(order.id, {
                    products: sanitizedProducts,
                })
                    .then(onDone)
                    .catch((err) => console.error('EditableOfferCard => handleSubmit', err));

            if (sanitizedProducts.filter((pp) => pp.unit_price === 0).length) {
                setDialog({
                    dialogOpen: true,
                    onConfirmDialog: localValidateOffer,
                    dialogContent: dialogs.freeWarning,
                });
            } else if (sanitizedProducts.filter((pp) => pp.unit_price <= 100000).length) {
                setDialog({
                    dialogOpen: true,
                    onConfirmDialog: localValidateOffer,
                    dialogContent: dialogs.lowPriceWarning,
                });
            } else {
                await localValidateOffer();
            }
        }
    };
    if (isLoading) {
        return <div />;
    }
    return (
        <OrderSection
            id="editable-offer-data"
            sx={{ height: '100%', maxHeight: 1, overflow: 'auto' }}>
            <Stack
                direction="column"
                minHeight="100%"
                justifyContent="space-between"
                alignItems="space-between">
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                        <ListAltIcon color="action" />
                        <Typography variant="h6" fontWeight="medium">
                            Productos
                        </Typography>
                    </Stack>
                    {inEmittedState(order) && (
                        <List dense>
                            {/* Electronic prescription con texto (name) */}
                            {hasOnlyElectronicPrescriptionData &&
                                electronicProducts.map((p) => <SimpleProductItem product={p} />)}
                            {/* OTC con texto (name)} */}
                            {!!otcProducts.filter((i) => i.name).length &&
                                otcProducts
                                    .filter((i) => i.name)
                                    .map((p) => <SimpleProductItem product={p} />)}
                        </List>
                    )}
                    {inEmittedState(order) && hasOnlyElectronicPrescriptionData && (
                        <Alert severity="info">
                            Este es un pedido con receta electrónica. En el siguiente paso podrás
                            ver la receta.
                        </Alert>
                    )}
                    {inEmittedState(order) && !!otcProducts.filter((i) => i.name).length && (
                        <Alert severity="info">
                            Estos son los productos de <b>venta libre</b> del paciente. Asegurarte
                            de tener stock de todos los medicamentos.
                        </Alert>
                    )}
                    {inEmittedState(order) &&
                        !hasOnlyElectronicPrescriptionData &&
                        !!prescriptionFilesUrls.length && (
                            <Typography
                                variant="body1"
                                fontWeight="normal"
                                color="grey.400"
                                align="center"
                                style={{ whiteSpace: 'pre-line' }}>
                                {filesInfoMessage(isOTC(order))}
                            </Typography>
                        )}
                    <FormContainer formContext={formContext}>
                        {inValidatingState(order) && (
                            <PrescriptionInput
                                order={order}
                                control={control}
                                disabled={inputState === 'disabled'}
                            />
                        )}
                    </FormContainer>
                </Stack>
                <FormContainer formContext={formContext} onSuccess={handleSubmit}>
                    <Stack direction="column" sx={{ my: 1 }}>
                        <Divider flexItem sx={{ my: 1 }} />
                        {inValidatingState(order) && (
                            <TotalAmount totalCharged={totalPrescriptionCharge} />
                        )}
                        <Grid2 container width="100%" alignItems="center">
                            {inEmittedState(order) && (
                                <Grid2 xsOffset="auto">
                                    <EmittedSubmitButton
                                        setScheduleEnabled={setScheduleEnabled}
                                        inputState={inputState}
                                    />
                                </Grid2>
                            )}
                            {inValidatingState(order) && (
                                <>
                                    <Grid2>
                                        <ToPreviousStateButton order={order} />
                                    </Grid2>
                                    <Grid2 sx={{ ml: 4 }}>
                                        <ReScheduleStock
                                            order={order}
                                            disabled={order.state !== 'assigned'}
                                        />
                                    </Grid2>
                                    <Grid2 xsOffset="auto">
                                        <ValidatingSubmitButton
                                            disabled={order.state !== 'assigned'}
                                        />
                                    </Grid2>
                                </>
                            )}
                        </Grid2>
                    </Stack>
                </FormContainer>
            </Stack>
            <ConfirmDialog
                title={dialogContent.title}
                message={dialogContent.message}
                open={dialogOpen}
                onClose={() => {
                    setDialog({ dialogOpen: false, onConfirmDialog, dialogContent });
                    setLoading(false);
                }}
                onConfirm={onConfirmDialog}
            />
            {/* CONFIRM SCHEDULE DIALOG */}
            <ConfirmDialog
                title="📆 Programar disponibilidad de stock"
                message="Seleccioná la fecha y hora en que todos los productos estarán listos para la entrega:"
                confirmButtonText="Programar"
                open={!isScheduled(order) && scheduledEnabled}
                onClose={() => setScheduleEnabled(false)}
                onConfirm={handleSubmit}
                extraContent={<ScheduleDatePicker control={control} inputState={inputState} />}
            />
        </OrderSection>
    );
}

export default EditableOfferCard;
