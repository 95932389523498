import { round } from 'reliable-round';
import { OfferProductsReqDto } from '../../../services/api/dto/orders.dto';
import { ProductField } from '../types/editableOfferCard.formValues';

export const sanitizeProductsForm = (products: ProductField[]): OfferProductsReqDto[] =>
    products
        .filter((prod) => !!prod.name)
        .map((p) => ({
            id: p.id,
            name: p.name,
            generic_name: p.generic_name,
            laboratory: p.laboratory,
            quantity: p.quantity,
            unit_price: round((p.unit_price || 0) * 100),
            discount_percent: p.discount_percent || 0,
        }));
