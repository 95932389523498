import { pharmaciesApi } from './config/delfarApi';
import { GetReferralUrlsResponseDto } from './dto/referrals.dto';

const authorizeMp = {
    warmup: (): Promise<void> =>
        pharmaciesApi.post<void>('/setup-mercado-pago', { code: 'warmup' }).then((r) => r.data),
    authorize: (code: string): Promise<void> =>
        pharmaciesApi.post<void>('/setup-mercado-pago', { code }).then((r) => r.data),
};

export const updateHealthProviders = async (hps: string[]): Promise<void> =>
    pharmaciesApi.post<void>('health_providers', { health_providers: hps }).then((r) => r.data);

export const getReferralUrls = async (): Promise<GetReferralUrlsResponseDto> =>
    pharmaciesApi.get<GetReferralUrlsResponseDto>('/referrals').then((r) => r.data);
export default {
    authorizeMp,
    updateHealthProviders,
    getReferralUrls,
};
