import { useQuery } from '@tanstack/react-query';
import { getQrUrls } from '../qrs.endpoints';
import { getReferralUrls } from '../pharmacies.endpoints';

export const useGetQrsAndReferrals = () => {
    const referralsQuery = useQuery({
        queryKey: ['getReferralUrls'],
        queryFn: getReferralUrls,
        staleTime: 24 * 3600 * 1000, // 24 horas
    });
    const qrsQuery = useQuery({
        queryKey: ['getQrUrls'],
        queryFn: getQrUrls,
        enabled: !!referralsQuery.data?.referral_urls?.length,
        staleTime: 24 * 3600 * 1000, // 24 horas
    });

    return {
        ...qrsQuery,
        qrUrls: qrsQuery.data?.qr_urls || [],
        referralUrls: referralsQuery.data?.referral_urls || [],
    };
};
