export enum DelfarApiEntity {
    SESSIONS = 'sessions',
    FILES = 'files',
    ORDERS = 'orders',
    SEARCH = 'search',
    DELIVERIES = 'deliveries',
    HEALTH_PROVIDERS = 'health_providers',
    QRS = 'qrs',
    REFERRALS = 'referrals',
    PHARMACIES = 'pharmacies',
    CUSTOMER_CHATS = 'customer_chats',
}
