import React, { Tooltip } from '@mui/material';
import { Control, TextFieldElement } from 'react-hook-form-mui';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { ProductsForm } from '../../../types/editableOfferCard.formValues';
import ProductSearchBar from './ProductSearchBar';
import { ProductSearch } from '../../../../../services/api/dto/search.dto';

const FONT_SIZE = 14;
const inputStyleQty = { mx: 1, width: '7rem' };
const inputStylePercent = { mx: 1, width: '7rem' };
const inputStylePrice = { mx: 1, width: '8rem' };
type ProductItemProps = {
    onClickRemoveItem: () => void;
    index: number;
    disabledInputs: boolean;
    control: Control<ProductsForm>;
    setProduct: (index: number, product: ProductSearch) => void;
};

export function ProductItem({
    onClickRemoveItem,
    index,
    disabledInputs,
    control,
    setProduct,
}: ProductItemProps) {
    const namePrefix = `products.${index}`;
    return (
        <ListItem
            sx={{
                px: 0,
                py: 1,
                ':hover': {
                    backgroundColor: '#edf6fc',
                },
            }}>
            <Typography variant="body2" color="grey.400" pr={1}>
                {index + 1}
            </Typography>
            <ProductSearchBar
                index={index}
                name={`products.${index}.name`}
                control={control}
                disabled={disabledInputs}
                setProduct={setProduct}
            />
            <TextFieldElement
                required
                type="number"
                name={`${namePrefix}.quantity`}
                label="Cant."
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
                variant="standard"
                disabled={disabledInputs}
                parseError={() => ''}
                validation={{ min: 1 }}
                sx={inputStyleQty}
                InputProps={{
                    inputProps: {
                        style: { fontSize: FONT_SIZE, textAlign: 'right' },
                    },
                }}
            />
            <TextFieldElement
                required
                type="number"
                name={`${namePrefix}.discount_percent`}
                label="Desc."
                size="small"
                variant="standard"
                disabled={disabledInputs}
                parseError={() => ''}
                validation={{ min: 0, max: 100 }}
                sx={inputStylePercent}
                InputProps={{
                    inputProps: {
                        style: { fontSize: FONT_SIZE, textAlign: 'right' },
                    },
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
            />
            <Tooltip
                title="Precio unitario del producto, sin el descuento aplicado"
                enterDelay={2000}>
                <span>
                    <TextFieldElement
                        required
                        type="number"
                        name={`${namePrefix}.unit_price`}
                        label="Precio u. sin desc."
                        size="small"
                        variant="standard"
                        disabled={disabledInputs}
                        parseError={() => ''}
                        validation={{ min: 0 }}
                        sx={inputStylePrice}
                        InputProps={{
                            inputProps: {
                                style: { fontSize: FONT_SIZE, textAlign: 'right' },
                            },
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </span>
            </Tooltip>
            {!disabledInputs && (
                <Tooltip title="Eliminar item" enterDelay={2000}>
                    <span>
                        <IconButton
                            sx={{ p: 1 }}
                            aria-label="delete"
                            size="small"
                            onClick={onClickRemoveItem}>
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </ListItem>
    );
}
