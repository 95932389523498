import React, { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { Control, Controller, TextFieldElement } from 'react-hook-form-mui';
import { useGetSearchProducts } from '../../../../../services/api/queries/useGetSearchProducts';
import { ProductsForm } from '../../../types/editableOfferCard.formValues';
import { ProductSearch } from '../../../../../services/api/dto/search.dto';

const MIN_QUERY_LENGTH = 2;
const MAX_QUERY_LENGTH = 15;
const isNumber = (str: string): boolean => !Number.isNaN(Number(str));

type ProductSearchBarProps = {
    index: number;
    name: `products.${number}.name`;
    control: Control<ProductsForm>;
    disabled: boolean;
    setProduct: (index: number, prod: ProductSearch) => void;
};
export default function ProductSearchBar({
    index,
    name,
    control,
    disabled,
    setProduct,
}: ProductSearchBarProps) {
    const [queryParam, setQueryParam] = useState<string>('');
    const { products, productsById } = useGetSearchProducts(queryParam);
    const getProductOptionLabelValue = (id: string): string => {
        const prod = productsById[id];
        if (!prod) return id;
        return `${prod.description} - ${prod.laboratory}`;
    };

    const getProductOptionRenderValue = (id: string): string => {
        const prod = productsById[id];
        if (!prod) {
            return id;
        }
        let displayName = '';
        if (isNumber(queryParam)) {
            displayName = `${prod.id} - `;
        }
        displayName += `${prod.description}`;
        if (prod.laboratory) {
            displayName += ` - ${prod.laboratory}`;
        }
        return displayName;
    };
    const handleInputChange = (
        _event: React.SyntheticEvent<Element, Event>,
        newInputValue: string,
    ) => {
        // para no realizar queries innecesarias
        if (newInputValue.length >= MIN_QUERY_LENGTH) {
            setQueryParam(newInputValue.substring(0, MAX_QUERY_LENGTH));
        }
    };
    const handleOptionSelect = (
        _event: React.SyntheticEvent<Element, Event>,
        selectedId: string,
    ) => {
        const product = productsById[selectedId];
        if (product) {
            setProduct(index, product);
        }
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    sx={{ width: '100%' }}
                    freeSolo
                    disableClearable
                    disabled={disabled}
                    value={field.value}
                    onInputChange={handleInputChange}
                    options={products.map((p) => p.id)}
                    getOptionLabel={getProductOptionLabelValue}
                    onChange={handleOptionSelect}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <Tooltip
                            title="Podés buscar por código o nombre del producto"
                            enterDelay={2000}
                            placement="right">
                            <span>
                                <TextFieldElement
                                    {...params}
                                    required
                                    type="string"
                                    name={`products.${index}.name`}
                                    label="Nombre"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    variant="standard"
                                    disabled={disabled}
                                    parseError={() => ''}
                                    sx={{ width: '100%' }}
                                />
                            </span>
                        </Tooltip>
                    )}
                    renderOption={(props, id, { inputValue: inVal }) => {
                        const renderValue = getProductOptionRenderValue(id);
                        const matches = match(renderValue, inVal, {
                            insideWords: true,
                        });
                        const parts = parse(renderValue, matches);
                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part) => (
                                        <span
                                            key={`${id}-span-${part.text}`}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                                fontSize: 14,
                                            }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            )}
        />
    );
}
