import { DefinedUseQueryResult } from '@tanstack/react-query/src/types';
import { useQuery } from '@tanstack/react-query';
import { searchProducts } from '../search.endpoints';
import { ProductSearch } from '../dto/search.dto';

export const useGetSearchProducts: (query?: string) => DefinedUseQueryResult & {
    products: ProductSearch[];
    productsById: Record<string, ProductSearch>;
} = (query?: string) => {
    const hook = useQuery({
        queryKey: ['getSearchProducts', query?.toLowerCase()],
        queryFn: ({ queryKey }) => searchProducts(queryKey[1]),
        enabled: !!query, // no realizar fetch hasta que se especifique query param
        initialData: {
            items: [],
            limit: 99999,
            total_items: 0,
            total_pages: 1,
            current_page: 1,
            next_page: null,
            previous_page: null,
        },
        initialDataUpdatedAt: 0, // para considerar desactualizada initialData y fetchear al toque
        staleTime: Infinity,
    });
    const { data } = hook;
    const productsById: Record<string, ProductSearch> = Object.fromEntries(
        data.items.map((prod) => [prod.id, prod]),
    );
    return {
        ...hook,
        products: data.items,
        productsById,
    };
};
