// eslint-disable-next-line import/no-named-default
import { PublicOrder, UserOffer } from '../Models';
import { translateHealthProvider } from '../services/HealthProviders';

export const debug: boolean = process.env.DEBUG === 'true' || false;

// **** DEPRECATED
export const getTotalCosts = (userOffer: UserOffer | undefined) => {
    if (!userOffer) return 0;
    let drugsTotal = 0;
    drugsTotal += userOffer.prescription_charged_price || 0;
    return drugsTotal;
};
// ****

export const whatsappLink = (phoneNumber: string) => `https://wa.me/${phoneNumber}`;

export const healthProviderTitle = (order: PublicOrder | undefined, hasOtc?: boolean): string => {
    if (!order) return '';
    let title = translateHealthProvider(order.health_provider);
    if (order.health_provider !== 'otc' && hasOtc) {
        title = `${title} + Venta Libre`;
    }
    return title;
};

export const capitalizeEachWord = (str: string | undefined): string =>
    (str || '').toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());

export const splitUrlsFromText = (input: string): { url?: string; text?: string }[] => {
    // Regular expression to match URLs
    const urlRegex = /^[^\s]*\.[^\s]*\/[^\s]*\s*$/gm;

    // Result array
    const result: { url?: string; text?: string }[] = [];

    let lastIndex = 0;

    // Find matches and split text
    input.replace(urlRegex, (url, offset) => {
        // Add non-URL text before the URL
        if (offset > lastIndex) {
            result.push({ text: input.slice(lastIndex, offset) });
        }

        // Add the detected URL as an object
        result.push({ url });

        // Update lastIndex to after the current URL
        lastIndex = offset + url.length;

        return url; // Needed for `replace` to work
    });

    // Add any remaining text after the last URL
    if (lastIndex < input.length) {
        result.push({ text: input.slice(lastIndex) });
    }

    return result;
};
