import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { BotmakerChat, ID } from '../../Models';
import { chatSubscription } from './subscriptions';
import { sendChatMsg } from '../api/customerChats.endpoints';
import { OrdersContextValue } from '../Orders';
import { useSoundNotifications } from '../../hooks/useSoundNotifications';

type BotmakerChatContextHook = {
    chat: BotmakerChat | undefined;
    sendMsg: (msg: string) => Promise<void>;
    selectCustomerById: (customerId: string | undefined) => void;
    chatState: Record<string, ChatState>;
    markAsRead: () => void;
};
const defaultState: BotmakerChatContextHook = {
    chat: undefined,
    sendMsg: () => Promise.resolve(),
    selectCustomerById: () => undefined,
    chatState: {},
    markAsRead: () => undefined,
};

export type ChatState = {
    state: 'new-message' | 'all-read';
    lastReadMsgId: string;
};
export const BotmakerChatContext = createContext(defaultState);

const DEBOUNCE_MILLISECONDS = 2000;
export const useBotmakerChat = (orders: OrdersContextValue): BotmakerChatContextHook => {
    const [chatsByCustomerId, setChatsByCustomerId] = useState<Record<ID, BotmakerChat>>({});
    const [customerId, setCustomerId] = useState<ID | undefined>(undefined);
    const customers = useRef<ID[]>([]);
    const { playNotification } = useSoundNotifications({ debounce: DEBOUNCE_MILLISECONDS });
    const chatState = useRef<Record<ID, ChatState>>({});

    useEffect(() => {
        // Cómo solo podemos suscribirnos a 30 chats, ordenamos los customers en orden de prioridad.
        // Asumimos que los IDS están ordenados por creation_timestamp desc
        const newCustomers = Array.from(
            new Set([
                ...orders.assigned.map((o) => o.customer_id),
                ...orders.approved
                    .filter((o) => ['approved', 'ready_for_pickup', 'delivering'].includes(o.state))
                    .map((o) => o.customer_id),
                ...orders.approved
                    .filter((o) => o.state === 'cancellation_requested')
                    .map((o) => o.customer_id),
                ...orders.approved
                    .filter(
                        (o) =>
                            ['fulfilled', 'cancelled'].includes(o.state) &&
                            dayjs().isBefore(dayjs(o.creation_timestamp).add(2, 'days')),
                    )
                    .map((o) => o.customer_id),
            ]),
        )
            .filter((c) => !!c)
            .slice(0, 30);

        if (!newCustomers.length || newCustomers.toString() === customers.current.toString()) {
            return;
        }
        console.log(
            'Subscribiendo a chats. Esto debería suceder solo cuando se agrega o elimina una orden',
        );
        customers.current = newCustomers;

        const processChatSnapshot = (chats: Record<ID, BotmakerChat>) => {
            // Esta función se va a llamar:
            // 1. a penas se carga la página
            // 2. cada vez que recibimos un mensaje u ocurrió un cambio en el chat
            // 3. cáda vez que ingresó/egresó una orden y ocurre un cambio de subscripción.

            const newChatState: Record<ID, ChatState> = {};
            let shouldNotifyNewMsg = false;
            Object.entries(chats).forEach(([cId, chat]) => {
                const { messages } = chat;
                const lastMsg = messages[messages.length - 1];
                const currChatState = chatState.current[cId];
                if (currChatState) {
                    // Este chat ya estaba suscripto
                    // Si el último mensaje leído, es el mismo que es último -> no hay mensaje nuevo
                    if (currChatState.lastReadMsgId === lastMsg.id) {
                        newChatState[cId] = {
                            state: 'all-read',
                            lastReadMsgId: lastMsg.id,
                        };
                    } else {
                        newChatState[cId] = {
                            state: 'new-message',
                            lastReadMsgId: currChatState.lastReadMsgId,
                        };
                        if (lastMsg.from === 'user') {
                            // Cuando hay un nuevo mensaje del usuario. Lo notificamos
                            shouldNotifyNewMsg = true;
                        }
                    }
                } else {
                    // Es la primera vez que recibimos este chat
                    newChatState[cId] = {
                        state: 'new-message',
                        lastReadMsgId: 'dummy',
                    };
                }
            });
            setChatsByCustomerId(chats);
            chatState.current = newChatState;
            if (shouldNotifyNewMsg) {
                playNotification('new_customer_message');
            }
        };
        chatSubscription(newCustomers, processChatSnapshot);
    }, [orders, playNotification]);

    const markAsRead = useCallback(() => {
        if (!customerId) {
            return;
        }
        const { messages } = chatsByCustomerId[customerId];
        const lastMsg = messages[messages.length - 1];
        chatState.current[customerId] = {
            state: 'all-read',
            lastReadMsgId: lastMsg.id,
        };
    }, [chatsByCustomerId, customerId]);

    const sendMsg = useCallback(
        (msg: string) => {
            if (!customerId) {
                return Promise.resolve();
            }
            const chatId = chatsByCustomerId[customerId]?.id;
            const cleanMsg = msg.trim();
            if (!chatId || !cleanMsg) {
                return Promise.resolve();
            }
            return sendChatMsg(chatId, cleanMsg);
        },
        [chatsByCustomerId, customerId],
    );
    return {
        chat: customerId ? chatsByCustomerId[customerId] : undefined,
        sendMsg,
        markAsRead,
        selectCustomerById: setCustomerId,
        chatState: chatState.current,
    };
};
