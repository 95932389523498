import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from 'firebase/firestore';
import { User } from '../../Models';

export const userDataConverter: FirestoreDataConverter<User> = {
    toFirestore(user: User): DocumentData {
        return user;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
        const user = snapshot.data(options)!;
        return {
            id: snapshot.id,
            address: user.address,
            contact_number: user.contact_number,
            email: user.email,
            coordinates: user.coordinates,
            name: user.name,
            health_providers: user.health_providers,
            delivery_zones: user.delivery_zones || [],
            mp_credentials_state: user.mp_credentials_state,
        };
    },
};
