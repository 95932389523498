import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { capitalizeEachWord } from '../../../../../utils/utils';
import { ElectronicProduct } from '../../../../../services/api/queries/usePrescriptionData';
import { Product } from '../../../../../Models';

type SimpleProductItemProps = {
    product: ElectronicProduct | Product;
};
export function SimpleProductItem({ product }: SimpleProductItemProps) {
    const helperProduct: ElectronicProduct = {
        generic_name: '',
        presentation: '',
        quantity: 1,
        ...product,
    };
    const primaryText = `${helperProduct.quantity}x ${capitalizeEachWord(helperProduct.name)}`;

    let secondaryText = '';
    if (helperProduct.generic_name) {
        secondaryText = `${helperProduct.generic_name}`;
    }
    if (helperProduct.presentation) {
        if (!helperProduct.generic_name) {
            secondaryText = `${helperProduct.presentation}`;
        } else {
            secondaryText += ` - ${helperProduct.presentation}`;
        }
    }
    return (
        <ListItem sx={{ py: 0.5 }} key={helperProduct.name}>
            <Typography fontSize={18}>{primaryText}</Typography>
            {secondaryText && (
                <Typography
                    fontSize={16}
                    sx={{
                        color: 'text.secondary',
                    }}>
                    {`${' - '}${secondaryText}`}
                </Typography>
            )}
        </ListItem>
    );
}
