import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../services/firebase';

export const useAppState = () => {
    const [appInitialized, setAppInitialized] = useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(app), (user) => {
            setIsSignedIn(!!user);
            setIsAuthLoaded(true);
        });
        return unsubscribe; // Cleanup on unmount
    }, []);

    useEffect(() => {
        if (isAuthLoaded) setAppInitialized(true);
    }, [isAuthLoaded]);
    return { appInitialized, isSignedIn };
};
