import { createContext, useEffect, useState } from 'react';
import { User } from '../../Models';
import { userSubscription } from './subscriptions';
import { loadHealthProviders } from '../HealthProviders';
import { constActivateRemoteConfig } from '../firebase/remoteConfig';

const defaultState = {
    loading: true,
    user: {} as User,
};

export const UserContext = createContext(defaultState);

const initializeGlobalStates = () =>
    Promise.all([loadHealthProviders(), constActivateRemoteConfig()]);

export const useUser = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({} as User);

    useEffect(() => {
        initializeGlobalStates().then(() => {
            userSubscription((u) => {
                if (u) {
                    setUser(u);
                }
                setLoading(false);
            });
        });
    }, []);

    return { loading, user };
};
