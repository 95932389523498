import { round } from 'reliable-round';
import { ProductField } from '../types/editableOfferCard.formValues';

export const getPrescriptionsCharge = (products: ProductField[]): number => {
    if (!products || !products.length) {
        return 0;
    }
    // calcular total con descuento de products
    let totalPrescCharged: number = 0;
    products.forEach((item) => {
        const unitPrice = item.unit_price || 0;
        const discountPercent = item.discount_percent || 0;
        const discountedPrice = unitPrice * (1 - discountPercent / 100);
        totalPrescCharged += discountedPrice * item.quantity;
    });
    // redondear
    return round(totalPrescCharged, 2);
};
