import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { BotmakerChat, ID } from '../../Models';
import { app } from '../firebase';
import { botmakerChatConverter } from './converters';

const db = getFirestore(app);

export const chatSubscription = (
    customerToSubscribe: string[],
    onLoad: (chats: Record<ID, BotmakerChat>) => void,
) =>
    // Recordar que solo podemos subscribirnos 30 ANDs
    // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
    {
        const cleanCustomers = customerToSubscribe.filter((c) => !!c).slice(0, 30);
        return onSnapshot(
            query(
                collection(db, 'botmaker_chats'),
                where('customer_id', 'in', cleanCustomers),
            ).withConverter(botmakerChatConverter),
            (snaps) => {
                const chats: Record<ID, BotmakerChat> = {};
                snaps.forEach((snap) => {
                    const chat = snap.data();
                    chats[chat.customer_id] = chat;
                });
                onLoad(chats);
            },
        );
    };
