import { useEffect } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import LinearProgress from '@mui/material/LinearProgress';
import Navbar from './Components/Navbar';
import History from './Views/History/History';
import Dashboard from './Views/Dashboard/Dashboard';
import Kanban from './Views/Orders/Kanban';
import LogIn from './Views/LogIn/LogIn';
import { queryClient } from './hooks/useQuery';
import { OrdersContext, useOrders } from './services/Orders';
import { UserContext, useUser } from './services/User';
import { useAppState } from './hooks/useAppState';
import { SoundNotificationsContext } from './hooks/useSoundNotifications';
import { DeliveriesContext, useDeliveries } from './services/Deliveries';
import useNotifications from './hooks/useNotifications';
import { LocalConfigContext, useLocalConfig } from './hooks/useLocalConfig';
import InitialChecks from './Components/InitialChecks/InitialChecks';
import { BotmakerChatContext, useBotmakerChat } from './services/BotmakerChat';

function AuthenticatedApp() {
    const user = useUser();
    const { soundNotifications, onOrdersSnapshot } = useNotifications(user.user.id);
    const deliveries = useDeliveries();
    const localConfig = useLocalConfig();
    const orders = useOrders(user.loading ? undefined : user.user, {
        requestDeliveryAutomatically: localConfig.requestDeliveryAutomatically,
    });
    const chatContext = useBotmakerChat(orders);

    useEffect(() => {
        onOrdersSnapshot(orders.ordersWithUpdates, orders.lastSnapshotAt);
    }, [onOrdersSnapshot, orders.ordersWithUpdates, orders.lastSnapshotAt]);
    return (
        <LocalConfigContext.Provider value={localConfig}>
            <SoundNotificationsContext.Provider value={soundNotifications}>
                <UserContext.Provider value={user}>
                    <DeliveriesContext.Provider value={deliveries}>
                        <OrdersContext.Provider value={orders}>
                            <BotmakerChatContext.Provider value={chatContext}>
                                <InitialChecks />
                                <Routes>
                                    <Route path="/" element={<Navbar />}>
                                        <Route
                                            path="/"
                                            element={<Navigate to="/orders" replace />}
                                        />
                                        <Route path="orders" element={<Kanban />} />
                                        <Route path="orders/:id" element={<Kanban />} />
                                        <Route path="dashboard" element={<Dashboard />}>
                                            <Route
                                                path="mercadopago-setup"
                                                element={<Dashboard />}
                                            />
                                        </Route>
                                        <Route path="historial" element={<History />} />
                                        <Route path="login" element={<Navigate to="/" replace />} />
                                        <Route path="*" element={<Navigate to="/" replace />} />
                                    </Route>
                                </Routes>
                            </BotmakerChatContext.Provider>
                        </OrdersContext.Provider>
                    </DeliveriesContext.Provider>
                </UserContext.Provider>
            </SoundNotificationsContext.Provider>
        </LocalConfigContext.Provider>
    );
}

function UnauthenticatedApp() {
    return (
        <Routes>
            <Route path="/login" element={<LogIn />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}

export default function App() {
    const { appInitialized, isSignedIn } = useAppState();

    if (!appInitialized) {
        return <LinearProgress />;
    }
    if (!isSignedIn) {
        return <UnauthenticatedApp />;
    }
    return (
        <QueryClientProvider client={queryClient}>
            <AuthenticatedApp />
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
}
