import { useCallback, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Control, useFieldArray } from 'react-hook-form-mui';
import List from '@mui/material/List';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PublicOrder } from '../../../../../Models';
import { ProductField, ProductsForm } from '../../../types/editableOfferCard.formValues';
import { ProductItem } from './ProductItem';
import { ProductSearch } from '../../../../../services/api/dto/search.dto';

const createEmptyItem = (): ProductField => ({
    discount_percent: undefined,
    id: '',
    name: '',
    quantity: 1,
    unit_price: 0,
});
type PrescriptionInputProps = {
    order: PublicOrder;
    disabled: boolean;
    control: Control<ProductsForm>;
};
function PrescriptionInput({ order, disabled, control }: PrescriptionInputProps) {
    const { fields, remove, append, update } = useFieldArray({
        control,
        name: 'products',
        rules: { minLength: 1 },
        keyName: 'key',
    });

    useEffect(() => {
        if (!fields.length) {
            append(createEmptyItem());
        }
    }, [append, fields.length]);
    const addListItem = () => append(createEmptyItem());

    const setProduct = useCallback(
        (index: number, product: ProductSearch) => {
            update(index, {
                ...fields[index],
                id: product.id,
                name: product.description,
                generic_name: product.monodrug,
                family: product.family,
                laboratory: product.laboratory,
                unit_price: product.price,
            });
        },
        [fields, update],
    );

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <List sx={{ width: 1 }}>
                {fields.map((field, i) => (
                    <ProductItem
                        key={`ProductItem-${field.key}`}
                        index={i}
                        onClickRemoveItem={() => remove(i)}
                        disabledInputs={disabled}
                        control={control}
                        setProduct={setProduct}
                    />
                ))}
            </List>
            {order.state !== 'validated' && (
                <Tooltip title="Agregar producto" enterDelay={2000}>
                    <Button
                        aria-label="add"
                        size="small"
                        variant="contained"
                        onClick={addListItem}
                        sx={{ width: '6rem' }}>
                        <AddCircleIcon />
                    </Button>
                </Tooltip>
            )}
        </Stack>
    );
}

export { PrescriptionInput };
