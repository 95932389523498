import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { poll } from '../services/auth/sessions';
import { setupMessaging } from '../services/firebase/messaging';
import { isLatestAppVersion } from '../services/webApp/version';

const sessionPollingInterval = parseInt(process.env.REACT_APP_SESSION_POLL_INTERVAL || '60000', 10);
const fcmRegistrationPollingInterval = parseInt(
    process.env.REACT_APP_FCM_POLL_INTERVAL || '2000000000',
    10,
);
const appVersionPollingInterval = parseInt(
    process.env.REACT_APP_APP_VERSION_POLL_INTERVAL || '3600000',
    10,
);

type SchedulerReturn = {
    sessionPolling: UseQueryResult<string>;
    fcmRegistration: UseQueryResult<boolean>;
    appVersionPolling: UseQueryResult<boolean>;
};

export function useScheduler(): SchedulerReturn {
    const sessionPolling = useQuery({
        queryKey: ['sessionPoll'],
        queryFn: poll,
        refetchInterval: sessionPollingInterval,
        refetchIntervalInBackground: true,
        retry: 6,
        keepPreviousData: true,
        // staleTime: sessionPollingInterval, // do not refresh twice en 10 seconds period
    });
    const fcmRegistration = useQuery({
        queryKey: ['fcmRegistration'],
        queryFn: setupMessaging,
        refetchInterval: fcmRegistrationPollingInterval,
        refetchIntervalInBackground: true,
        retry: 10,
        keepPreviousData: true,
        // staleTime: fcmRegistrationPollingInterval, // do not refresh twice en 10 seconds period
        cacheTime: 0,
    });
    const appVersionPolling = useQuery({
        queryKey: ['appVersionPolling'],
        queryFn: isLatestAppVersion,
        refetchInterval: appVersionPollingInterval,
        refetchIntervalInBackground: false,
        retry: 10,
        keepPreviousData: true,
        // staleTime: appVersionPollingInterval, // do not refresh twice en 10 seconds period
        cacheTime: 0,
    });
    return {
        sessionPolling,
        fcmRegistration,
        appVersionPolling,
    };
}
