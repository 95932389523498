import dayjs from 'dayjs';
import { Product, UserOffer } from '../../../Models';
import { ProductsForm, ProductField } from '../types/editableOfferCard.formValues';
import { capitalizeEachWord } from '../../../utils/utils';
import { ElectronicProduct } from '../../../services/api/queries/usePrescriptionData';
import { searchProducts } from '../../../services/api/search.endpoints';

export const getInitFormValues = async (
    offer: UserOffer | undefined,
    electronicProducts: ElectronicProduct[],
    otcProducts: Product[],
): Promise<ProductsForm> => {
    const products: ProductField[] = [];
    if (!offer) {
        return {
            products,
            scheduled_stock_datetime: dayjs().set('minutes', 0).set('seconds', 0).add(1, 'hour'),
        };
    }
    if (!offer.products?.length) {
        const helperList: ProductField[] = [];
        electronicProducts?.map((prod) =>
            helperList.push({
                id: '',
                name: capitalizeEachWord(`${prod.name} ${prod.presentation}`),
                generic_name: prod.generic_name,
                quantity: prod.quantity,
                unit_price: 0,
                discount_percent: 0,
            }),
        );
        otcProducts.forEach((prod) =>
            helperList.push({
                id: '',
                name: prod.name || '',
                generic_name: '',
                quantity: 1,
                unit_price: 0,
                discount_percent: 0,
            }),
        );
        // Buscamos valores defaults de precio
        products.push(
            ...(await Promise.all(
                helperList.map(async (pf) => {
                    let prod: ProductField = { ...pf };
                    const query = pf.id.trim() || pf.name.trim();
                    if (query) {
                        const res = await searchProducts(query);
                        if (res.total_items === 1) {
                            const item = res.items[0];
                            prod = {
                                ...prod,
                                ...item,
                                unit_price: item.price,
                                name: item.description,
                                generic_name: item.monodrug,
                            };
                        }
                    }
                    return prod;
                }),
            )),
        );
    } else {
        offer.products?.forEach((product) => products.push(product));
    }

    return {
        products,
        scheduled_stock_datetime: dayjs(offer.scheduled_stock_datetime),
    };
};
