import { fetchAndActivate, getRemoteConfig, getString as _getString } from 'firebase/remote-config';
import { app } from './index';

// La app no es de uso masivo. Por ahora vamos con 30 minutos

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 30 * 60 * 1000;
export const constActivateRemoteConfig = () => fetchAndActivate(remoteConfig);

export const getString = (key: string, defaultValue: string): string => {
    const strValue = _getString(remoteConfig, key);
    return strValue || defaultValue;
};
