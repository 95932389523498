import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { defaultTheme } from './themes';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.locale('es');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    /* <React.StrictMode> */
    <BrowserRouter>
        <CssBaseline />
        <ThemeProvider theme={defaultTheme}>
            <IntlProvider locale="es-AR">
                <App />
            </IntlProvider>
        </ThemeProvider>
    </BrowserRouter>,
    /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
