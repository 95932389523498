import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DisclosedOrder, PublicOrder } from '../../../../Models';
import OrderDialogTitle from './OrderDialogTitle';
import OrderDialogContent from './OrderDialogContent';

type OrderDialogProps = {
    order: PublicOrder | DisclosedOrder;
    closeOrder: () => void;
    open: boolean;
};

function OrderDialog({ order, closeOrder, open }: OrderDialogProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const [loading, setLoading] = useState(false);
    const [openChat, setOpenChat] = useState(false);

    const onToggleChat = async () => setOpenChat(!openChat);
    const onDone = () => {
        setLoading(false);
        closeOrder();
    };
    return (
        <Dialog
            id={`order-dialog-${order.id}`}
            scroll="paper"
            open={open}
            onClose={closeOrder}
            PaperProps={{
                sx: {
                    height: '95vh',
                    width: '100dvw',
                },
            }}
            maxWidth={false}
            fullScreen={fullScreen}>
            <OrderDialogTitle order={order} closeOrder={closeOrder} onOpenChat={onToggleChat} />

            <OrderDialogContent
                order={order}
                setLoading={setLoading}
                onDone={onDone}
                openChat={openChat}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (_theme) => _theme.zIndex.drawer + 1 }}
                open={loading}
                transitionDuration={{ appear: 500, enter: 500, exit: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
}

export default OrderDialog;
