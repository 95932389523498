import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { getPrescriptionData } from '../orders.endpoints';
import { ElectronicPrescription, Product, PublicOrder } from '../../../Models';

export type ElectronicProduct = {
    name: string;
    quantity: number;
    generic_name?: string;
    presentation?: string;
};
const config = {
    initialDataUpdatedAt: 0, // para considerar desactualizada initialData y fetchear al toque
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
};
export type PrescriptionData = DefinedUseQueryResult & {
    hasOnlyElectronicPrescriptionData: boolean | undefined;
    hasSomeElectronicPrescriptionData: boolean | undefined;
    otcProducts: Product[];
    paperPrescriptions: string[];
    hasPaperPrescriptions: boolean;
    electronicProducts: ElectronicProduct[];
    prescriptionFilesUrls: string[];
    hasElectronicPrescriptions: boolean;
    electronicPrescriptions: ElectronicPrescription[];
};
export const usePrescriptionData = (order: PublicOrder): PrescriptionData => {
    const hook = useQuery({
        queryKey: ['getPrescriptionData', order.id],
        queryFn: ({ queryKey }) => getPrescriptionData(queryKey[1]),
        initialData: {
            electronic_prescriptions: [],
            paper_prescriptions: [],
            otc_products: [],
        },
        ...config,
    });

    const { data: prescription } = hook;
    const hasPaperPrescriptions =
        !!prescription?.paper_prescriptions?.length || !!prescription?.otc_products?.length;
    const hasElectronicPrescriptions = !!prescription?.electronic_prescriptions?.length;
    const hasOnlyElectronicPrescriptionData =
        !prescription.otc_products?.length &&
        !hasPaperPrescriptions &&
        prescription?.electronic_prescriptions?.every((presc) => !!presc?.prescription_data);
    const hasSomeElectronicPrescriptionData = prescription?.electronic_prescriptions?.some(
        (presc) => !!presc?.prescription_data,
    );
    const electronicProducts: ElectronicProduct[] = hasSomeElectronicPrescriptionData
        ? prescription?.electronic_prescriptions
              ?.flatMap((ep) => ep?.prescription_data?.products || [])
              ?.map((p) => ({
                  name: p.name,
                  quantity: p.quantity,
                  presentation: p.presentation,
                  generic_name: p.generic_name,
              })) || []
        : [];
    const prescriptionFilesUrls = [
        ...(prescription.paper_prescriptions || []),
        ...(prescription.electronic_prescriptions?.map((p) => p.url) || []),
        ...(prescription.otc_products
            ?.filter((i) => i.url) // <== solo otc con url de imagen (dejamos fuera los que solo tienen "name")
            .map((p) => p.url) || []),
    ];

    return {
        ...hook,
        hasPaperPrescriptions,
        hasElectronicPrescriptions,
        hasOnlyElectronicPrescriptionData,
        hasSomeElectronicPrescriptionData,
        electronicProducts,
        paperPrescriptions: prescription.paper_prescriptions || [],
        electronicPrescriptions: prescription.electronic_prescriptions || [],
        otcProducts: prescription.otc_products || [],
        prescriptionFilesUrls,
    };
};
