import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Control, Controller } from 'react-hook-form-mui';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { InputState } from '../InputState';
import { ProductsForm } from '../../../types/editableOfferCard.formValues';
import { StyledDatePicker } from '../../../../../Components/StyledDatePicker';

const SCHEDULED_DATE_TOOLTIP =
    'Fecha y hora en la cual tendrás el medicamento en stock.\n\nEl paciente será informado que el medicamento será enviado en esta fecha y hora.';

function ScheduleDatePicker({
    control,
    inputState,
}: {
    control: Control<ProductsForm>;
    inputState: InputState;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            sx={{ py: 1 }}>
            <Typography variant="body1">Fecha de disponibilidad:</Typography>
            <Tooltip
                title={
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                        {SCHEDULED_DATE_TOOLTIP}
                    </Typography>
                }
                placement="top">
                <InfoIcon sx={{ ml: 2 }} color="info" fontSize="small" />
            </Tooltip>
            <Controller
                control={control}
                name="scheduled_stock_datetime"
                rules={{ required: true }}
                render={({ field }) => (
                    <StyledDatePicker
                        disabled={inputState === 'disabled'}
                        value={field.value}
                        inputRef={field.ref}
                        onChange={field.onChange}
                    />
                )}
            />
        </Stack>
    );
}

export { ScheduleDatePicker };
